.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bold-500 {
  font-weight: 500 !important;
}

.last_update {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #778399;
}

select {
  cursor: pointer;
}

/* 
input[type="checkbox"] {
  accent-color: rgba(15, 51, 124, 1);
  width: 15px;
  height: 15px;

  cursor: pointer;
}

input[type="radio"] {
  accent-color: rgba(15, 51, 124, 1);
  width: 15px;
  height: 15px;
  cursor: pointer;
} */

/* width */
/* 
.Toastify__toast--error {
  border-radius: 12px !important;
  border: 1px solid var(--Black-200, #E2E3E5) !important;
  background: var(--White, #FFF) !important;
  box-shadow: 0px 4px 6px 0px rgba(5, 14, 33, 0.08) !important;
}

.Toastify__toast--error::after {
  content: url('./assets/icons/toast-close.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--error::before {
  content: url('./assets/icons/toast-error.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 8px;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast--success {
  border-radius: 12px !important;
  border: 1px solid var(--Black-200, #E2E3E5) !important;
  background: var(--White, #FFF) !important;
  box-shadow: 0px 4px 6px 0px rgba(5, 14, 33, 0.08) !important;
}

.Toastify__toast--success::before {
  content: url("./assets/icons/toast-success.svg");
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 8px;
}

.Toastify__toast--success::after {
  content: url('./assets/icons/toast-close.svg');
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--warning {
  border-radius: 12px !important;
  border: 1px solid var(--Black-200, #E2E3E5) !important;
  background: var(--White, #FFF) !important;
  box-shadow: 0px 4px 6px 0px rgba(5, 14, 33, 0.08) !important;
}

.Toastify__toast--warning::before {

  position: relative;
  z-index: 100000;
  left: 12px;
  top: 8px;
}

.Toastify__toast--warning::after {
  content: url('./assets/icons/toast-close.svg');
  position: absolute;
  color: #E78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast-body {
  color: #444C63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  font-family: Inter;
  width: 100%;
  font-weight: 500;
  margin-right: 38px !important;
  margin-left: 25px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  height: 42px;
}

.Toastify__progress-bar {
  visibility: hidden !important;
}

.Toastify__toast>button>svg {
  display: none;
} */
input[type="radio"] {
  border: 0.1em solid #0f337c !important;
}

input[type="radio"]:checked {
  border: 0.35em solid #0f337c !important;
  background-color: #ffffff !important;
}

.dark-theme input[type="radio"] {
  border: 0.1em solid #ffffff !important;
}

.dark-theme input[type="radio"]:checked {
  border: 0.35em solid #ffffff !important;
  background-color: #333333 !important;
}

input[type="radio"] {
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 0.15em solid #0f337c !important;
  border-radius: 50%;
  transform: translateY(-0.075em);
}

input[type="radio"]:checked {
  appearance: none;
  background-color: black;
  margin: 0;
  font: inherit;
  color: #0f337c;
  /* width: 1em;
  height: 1em; */
  width: 1em;
  height: 1em;
  border: 5px solid #0f337c;
  border-radius: 50%;
  transform: translateY(-0.075em);
}

/* Hide the default checkbox */
/* Default checkbox styles */
input[type="checkbox"] {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  appearance: none;
  border: 1px solid #0f337c;
  background-color: #ffffff;
  border-radius: 4px;
  transition: background-color 0.3s, border 0.3s;
}

/* Checkbox checked state */
input[type="checkbox"]:checked {
  background-color: #0f337c;
  border: 2px solid #0f337c;
}

/* Checkbox checked state in dark theme */
.dark-theme input[type="checkbox"]:checked {
  background-color: #0f337c;
  border: 2px solid #0f337c;
}

/* Dark theme styles for checkboxes */
.dark-theme input[type="checkbox"] {
  border: 2px solid #ffffff;
  background-color: #333333;
}

/* Checkmark styles */
input[type="checkbox"]::after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Checkmark visibility for checked state */
input[type="checkbox"]:checked::after {
  display: block;
}

.infinite-scroll-component__outerdiv .infinite-scroll-component {
  /* overflow-y: hidden !important; */
  /* height: 31rem !important; */
}

.InfiniteScrollClass {
  overflow: none !important;
}

.infinite-scroll-component__outerdiv
  .infinite-scroll-component::-webkit-scrollbar {
  height: 5px !important;
}

.form-fields-control input {
  height: 35px !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.form-fields-control textarea {
  border: 1px solid rgb(226, 227, 229);
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.form-fields-control input:focus,
select:focus,
textarea:focus {
  /* border: 2px solid #000 !important; */
  outline: none !important;
  box-shadow: none !important;
}

.form-fields-control select {
  height: 35px !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.form-fields-control .error-space {
  height: 16px;
}

.form-fields-control select {
  background: url("./assets/icons/arrow-down-3101.png") no-repeat !important;
  background-size: 9px auto !important;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  padding-left: 10px !important;
  padding-right: 2rem !important;
  -webkit-appearance: none;
  appearance: none !important;
}

.form-fields-control input[type="radio"] {
  height: 1em !important;
}

.user-radio input[type="radio"]:checked {
  border: 5px solid #0f337c !important;
}

.user-radio input[type="radio"] {
  border: 2px solid #778399 !important;
  cursor: pointer !important;
}

.toasterr .Toastify__toast-container {
  width: 380px;
  height: 64px;
}

.Toastify__toast-body {
  align-items: start !important;
}
.toasterr .Toastify__toast-body::first-letter {
  text-transform: capitalize !important;
}
.toasterr .Toastify__toast-container::first-letter {
  text-transform: capitalize !important;
}
.toasterr .Toastify__toast-body > div:last-child::first-letter {
  text-transform: capitalize !important;
}
.toasterr .Toastify__toast-body > div:last-child {
  margin-left: 15%;
  color: #031227 !important;
}
.toasterr .Toastify__toast-theme--light {
  border-radius: 12px;
  /* background-image: linear-gradient(to right, #F1FEF5 , white); */
}
.toasterr .Toastify__toast--success {
  background-image: linear-gradient(to right, #f1fef5, white, white);
}
.toasterr .Toastify__toast--error {
  background-image: linear-gradient(to right, #fef5f4, white, white);
}
.toasterr .Toastify__toast-body > div:last-child .mainText {
  color: #031227 !important;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.toasterr .Toastify__toast-body > div:last-child .subText {
  color: #4f5968 !important;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.toasterr .Toastify__toast-icon {
  width: 18px;
}
.toasterr .Toastify__toast-icon img {
  margin-right: -8%;
}

/* Add this to your CSS file */
.custom-toast {
  display: flex;
  align-items: center; /* Align items vertically */
}

.custom-toast .Toastify__toast-body {
  flex-grow: 1; /* Ensure the body takes available space */
  text-align: left; /* Align the text to the left */
}

.custom-toast
  .Toastify__toast-icon
  .Toastify--animate-icon
  .Toastify__zoom-enter {
  margin-top: 15px !important;
}

.custom-toast .Toastify__zoom-enter {
  margin-top: 15px !important;
}

.custom-toast .Toastify__toast-body > div:last-child {
  padding: 0px 8px !important;
}

.custom-toast .Toastify__close-button {
  margin-left: 5px !important;
  margin-top: 17px !important; /* Move the close button to the right */
}
