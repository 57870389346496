body {
  margin: 0;
  font-family: "Inter" !important;
  src: url("./assets/fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #efeded;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 56%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor-pointer {
  cursor: pointer !important;
}