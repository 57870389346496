.custom-input {
  border-radius: 8px;
  padding-left: 10px;
  color: #606162;
  font-family: Inter;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
}

.custom-input-dark {
  background-color: #232323;
  color: #ffffff;
  border: 1px solid #333;
}

input:focus-visible {
  outline-offset: none;
  outline: none !important;
}

.placeholderss::-webkit-input-placeholder {
  color: #c0c3cc; /* Chrome/Opera/Safari */
  font-size: 14px;
  font-weight: 300;
}
