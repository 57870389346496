.banner-section {
  background: #153F95;
  height: 100vh !important;
}

.banner-section-logo p {
  display: grid;
  justify-items: center;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
}

.banner-section-logo {
  text-align: center;
}

.input-sign-in {
  border-radius: 8px;
  border: 1px solid #E2E3E5;
  background: #FFF;
  width: 100%;
  height: 41px;
  padding-left: 10px;
}

.sign-btn {
  border-radius: 8px;
  background: #0F337C;
  box-shadow: 0px 4px 8px 0px rgba(5, 14, 33, 0.04);
  color: white;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  border: none;
}

.label-tag {
  color: #5b5c5e !important;
}

.sign-in-UI-button {
  padding-left: 80px;
  padding-right: 80px;
}

.error {
  color: red;
}

.eyeSlash-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.sup-symbol {
  color: red;
}

.git-tag {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin: 0;
  /* border: 1px solid #7d7474; */
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Inter';
  padding: 4px 10px;

}