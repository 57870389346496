.bottom-aligned-modal .modal-dialog {
  position: absolute;
  bottom: 50px;
  margin: 0;
  left: 35%;
  width: 100%;
}

.bottom-aligned-modal .modal-content {
  width: 120% !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  /* Custom box shadow */
  border-radius: 8px;
}

.toasterr .Toastify__toast-container {
  width: 380px;
  height: 64px;
}

.Toastify__toast-body {
  align-items: start !important;
}

.toasterr .Toastify__toast-body::first-letter {
  text-transform: capitalize !important;
}

.toasterr .Toastify__toast-container::first-letter {
  text-transform: capitalize !important;
}

.toasterr .Toastify__toast-body>div:last-child::first-letter {
  text-transform: capitalize !important;
}

.toasterr .Toastify__toast-body>div:last-child {
  margin-left: 15%;
  color: #031227 !important;
}

.toasterr .Toastify__toast-theme--light {
  border-radius: 12px;
  /* background-image: linear-gradient(to right, #F1FEF5 , white); */
}

.toasterr .Toastify__toast--success {
  background-image: linear-gradient(to right, #f1fef5, white, white);
}

.toasterr .Toastify__toast--error {
  background-image: linear-gradient(to right, #fef5f4, white, white);
}

.toasterr .Toastify__toast-body>div:last-child .mainText {
  color: #031227 !important;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.toasterr .Toastify__toast-body>div:last-child .subText {
  color: #4f5968 !important;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.toasterr .Toastify__toast-icon {
  width: 18px;

}

.toasterr .Toastify__toast-icon img {
  margin-right: -8%
}
/* 
.custom-modal {
  pointer-events: none;
}

.custom-modal .modal-content {
  pointer-events: auto;
} */
